import styled from '@emotion/styled';

export default styled.div`
    background: white;
    padding: 1em 0;

    &.is-scrolled-to-top {
        background: transparent;
    }

    .layout-nav-section {
        .layout-nav-element {
            font-size: 90%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .layout-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .col {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &.col-right {
                gap: 2em;

                .profile {
                    .profile-button {
                        min-width: 38px;
                        width: 38px;

                        .button-icon-after {
                            padding: 0.4em 0.5em;
                            margin: 0;
                            border-radius: 0.6em;
                            border: 2px solid transparent;
                        }

                        .profile-link-logged-in {
                            .button {
                                .button-icon-after {
                                    border-color: #ba9349;
                                }
                            }
                        }
                    }
                }

                .cart {
                    position: relative;
                    cursor: pointer;

                    .cart-button {
                        position: relative;

                        .cart-counter {
                            position: absolute;
                            width: 22px;
                            height: 22px;
                            background-color: #ba9349;
                            border-radius: 100px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: white;
                            z-index: 1;
                            font-size: 14px;
                            bottom: -12px;
                            right: -12px;
                            transition: all 100ms ease;
                            line-height: 1;

                            &.added {
                                transform: scale(1.5);
                                font-weight: 700;
                            }

                            &:after {
                                content: '';
                                position: absolute;
                                left: -130%;
                                top: -110%;
                                width: 260%;
                                height: 220%;
                            }
                        }
                    }

                    .public-layout-cart-drop-down-menu {
                        position: absolute;
                        opacity: 0;
                        top: 100%;
                        transform: translate(0, 0);
                        z-index: 2;
                        pointer-events: none;
                        transition: opacity 200ms ease, transform 200ms ease;
                    }

                    &:hover {
                        .public-layout-cart-drop-down-menu {
                            transform: translate(0, 20px);
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }
            }
        }
    }
`;
